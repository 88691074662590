.chart-date {
    font-size: 4px;
    font-weight: 700;
    fill: dimgrey;
}

.sport-label {
    font-size: 3.6px;
    font-weight: 700;
    fill: dimgrey;
}

.value-label-in {
    font-size: 3.6px;
    font-weight: 700;
    fill: white;
}

.value-label-out {
    font-size: 3.6px;
    font-weight: 700;
    fill: black;
}

/* https://www.computerhope.com/htmcolor.htm#color-codes */

.Run {
    fill: #E56717; /* Papaya orange */
}

.Ride {
    fill: #347235; /* Medium Forest Green */
}

.NordicSki {
    fill: #1569C7; /* Blue Eyes */
}

.Workout {
    fill: #7E354D; /* Velvet Maroon */
}

.Golf {
    fill: #4AA02C; /* Spring Green */
}

.Swim {
    fill: #15317E; /* Lapis Blue */
}

.WeightTraining {
    fill: #504A4B; /* Gray Wolf */
}

.AlpineSki {
    fill: #659EC7; /* Blue Koi */
}

