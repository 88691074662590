table {
    padding: 0.4vw;
    min-width: 100%;
}

td {
    padding: 0.1vw;
}

.Col1 {
    width: 30%;
}

.Col2 {
    width: 50%;
}

.Col3 {
    width: 20%;
}
