h4 {
    font-weight: 700;
    padding: 0.2vw;
    margin: 0;
}

h5 {
    font-weight: 700;
    padding: 0.2vw;
    margin: 0;
}

.dropdown {
    /* box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2); */
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 0.8vw;
    background-color: whitesmoke;
    border-color: darkgrey;
    font-weight: 600
}

.results {
    font-size: 1.4vmin;
    text-align: center;
    padding: 0.3em;
}

.best_1 {
    background-color: palegreen;
    font-weight: 800;
}

.best_2 {
    background-color: gainsboro;
    font-weight: 800;
}

.best_3 {
    background-color: whitesmoke;
    font-weight: 800;
}
