.App-header {
    background-color: #387824;
    font-variant: small-caps;
    color: white;
    padding: 0.2vmin 0.1vmin 0.2vmin 0.4vmin;
}

    .App-header a {
        text-decoration: none;
        color: inherit;
    }

.App-header-icon {
    vertical-align: -70%;
    height: 5vmin;
    margin: 0.4vmin;
    width: auto;
}
.App-header-brand {
    vertical-align: -10%;
    font-size: 3.0vmin;
    letter-spacing: 0.3vmin;
    margin-left: 2vmin;
    margin-bottom: 0.5vmin;
    font-weight: 900;
}

.navbar-item {
    vertical-align: -10%;
    font-size: 2.3vmin;
    letter-spacing: 0.2vmin;
    margin-left: 4vmin;
    margin-bottom: 0.5vmin;
}

.navbar-item:hover {
    background-color: #408830;
}

