.App {
    text-align: left;
    padding: 1.0vmax;
    font-size: 1.8vmin;
    background-color: gainsboro;
}

.App-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
}

.App-column {
    min-width:70vmin;
    max-width:100vmin;
    display: inline-block;
    margin: 0.5vw;
    padding: 0.5vw;
}

.App-frame {
    margin: 0.8vw;
    padding: 0;
    min-width: 100%;
    border-style: solid;
    border-color: darkgrey;
    /*border-color: #387824; [> Puisto green <]*/
    border-width: 0.1vw;
    background-color: white;
}

h3 {
    font-weight: 700;
    border-bottom: 0.1vw solid darkgrey;
    background-color: #e8e8e8;
    padding: 0.2vw;
    margin: 0;
}

